import React, { useState, useEffect } from 'react';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { useUpdateCompanyInfoMutation } from 'components/dashboard/profile/helper';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { COUNTRIES, INDUSTRIES, ROLES } from 'components/onboarding/step-three/constants';
import { CraitButton } from 'components/common/CraitButton';
import { Separator } from 'components/ui/separator';

type WorkInfoFormProps = {
  data: {
    companyName: string;
    phoneCountryCode: number;
    phoneNumber: string;
    industry: string;
    employeeRole: string;
  };
  onCloseModal: () => void;
  refetch: () => void;
};

export const WorkInfoForm: React.FC<WorkInfoFormProps> = ({ data, onCloseModal, refetch }) => {
  const [formState, setFormState] = useState({
    companyName: data.companyName || '',
    countryCode: data.phoneCountryCode || '90',
    phoneNumber: data.phoneNumber || '',
    selectedIndustry: data.industry
      ? INDUSTRIES.includes(data.industry)
        ? data.industry
        : 'Other'
      : '',
    otherIndustry: data.industry ? (INDUSTRIES.includes(data.industry) ? '' : data.industry) : '',
    selectedRole: data.employeeRole
      ? ROLES.includes(data.employeeRole)
        ? data.employeeRole
        : 'Other'
      : '',
    otherRole: data.employeeRole
      ? ROLES.includes(data.employeeRole)
        ? ''
        : data.employeeRole
      : '',
  });

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const updateCompanyInfoMutation = useUpdateCompanyInfoMutation();

  const sortedCountries = COUNTRIES.sort((a, b) => a.phone_code - b.phone_code);

  useEffect(() => {
    setFormState({
      companyName: data.companyName || '',
      countryCode: data.phoneCountryCode || '90',
      phoneNumber: data.phoneNumber || '',
      selectedIndustry: data.industry
        ? INDUSTRIES.includes(data.industry)
          ? data.industry
          : 'Other'
        : '',
      otherIndustry: data.industry ? (INDUSTRIES.includes(data.industry) ? '' : data.industry) : '',
      selectedRole: data.employeeRole
        ? ROLES.includes(data.employeeRole)
          ? data.employeeRole
          : 'Other'
        : '',
      otherRole: data.employeeRole
        ? ROLES.includes(data.employeeRole)
          ? ''
          : data.employeeRole
        : '',
    });
  }, [data]);

  const handleChange = (key: string, value: string) => {
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = () => {
    const {
      companyName,
      countryCode,
      phoneNumber,
      selectedIndustry,
      otherIndustry,
      selectedRole,
      otherRole,
    } = formState;

    const role = selectedRole === 'Other' ? otherRole : selectedRole;
    const industry = selectedIndustry === 'Other' ? otherIndustry : selectedIndustry;

    updateCompanyInfoMutation.mutate(
      {
        companyName,
        phoneNumber,
        phoneCountryCode: parseInt(countryCode.toString(), 10),
        industry,
        employeeRole: role,
      },
      {
        onSuccess: () => {
          refetch();
          onCloseModal();
        },
        onError: (error: any) => {
          console.error('Failed to update company info:', error);
        },
      },
    );
  };

  return (
    <>
      <div className='mb-2 flex flex-col gap-4 px-4 pt-12 md:p-0 md:pt-6'>
        {/* Company Name Input */}
        <div className='flex flex-col gap-2'>
          <Label htmlFor='company-name'>Company Name</Label>
          <Input
            id='company-name'
            placeholder='Company Name'
            className='bg-white'
            value={formState.companyName}
            onChange={(e) => handleChange('companyName', e.target.value)}
          />
        </div>

        {/* Company Number Input with Country Code */}
        <div className='flex flex-col gap-2'>
          <Label htmlFor='company-number'>Company Number</Label>
          <div className='flex gap-3'>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild className='rounded-md border-[#EAEAEA]'>
                <Button
                  variant='outline'
                  role='combobox'
                  aria-expanded={open}
                  className='w-[200px] justify-between'
                >
                  <span className='flex-1 text-main'>
                    {formState.countryCode ? `+${formState.countryCode}` : 'Select code...'}
                  </span>
                  <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                </Button>
              </PopoverTrigger>
              <PopoverContent className='h-[200px] w-[200px] p-0'>
                <Command
                  filter={(value, search) => {
                    const normalizedSearch = search.startsWith('+') ? search.slice(1) : search;
                    const extendValue = value + ' ';
                    if (extendValue.startsWith(normalizedSearch)) return 1;
                    return 0;
                  }}
                >
                  <CommandInput placeholder='Search country code...' />
                  <CommandList>
                    <CommandEmpty>No code found.</CommandEmpty>
                    <CommandGroup>
                      {sortedCountries.map((country) => (
                        <CommandItem
                          key={country.country_en}
                          value={country.phone_code.toString()}
                          onSelect={(currentValue) => {
                            setValue(currentValue === value ? '' : currentValue);
                            handleChange('countryCode', currentValue);
                            setOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              value === country.phone_code.toString() ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                          +{country.phone_code.toString()}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>

            <Input
              placeholder='Enter phone number'
              type='text'
              className='bg-white'
              value={formState.phoneNumber}
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^\d\s]/g, '');
                handleChange('phoneNumber', newValue);
              }}
            />
          </div>
        </div>

        {/* Industry Selection */}
        <div className='flex flex-col gap-2'>
          <Label>Industry</Label>
          <div className='flex flex-wrap gap-2'>
            {INDUSTRIES.map((industry) => (
              <Button
                key={industry}
                variant='outline'
                onClick={() => handleChange('selectedIndustry', industry)}
                className={`h-[26px] rounded border px-2 py-1 text-[12px] font-normal ${
                  formState.selectedIndustry === industry ? 'border-black' : 'border-gray-300'
                }`}
              >
                {industry}
              </Button>
            ))}
          </div>

          {formState.selectedIndustry === 'Other' && (
            <Input
              placeholder='Please specify industry'
              className='mt-2 bg-white focus-visible:ring-0 focus-visible:ring-offset-0'
              value={formState.otherIndustry}
              onChange={(e) => handleChange('otherIndustry', e.target.value)}
              disabled={formState.selectedIndustry !== 'Other'}
            />
          )}
        </div>

        {/* Role Selection */}
        <div className='flex flex-col gap-2'>
          <Label>Role</Label>
          <div className='flex flex-wrap gap-2'>
            {ROLES.map((role) => (
              <Button
                key={role}
                variant='outline'
                onClick={() => handleChange('selectedRole', role)}
                className={`h-[26px] rounded border px-2 py-1 text-[12px] font-normal ${
                  formState.selectedRole === role ? 'border-black' : 'border-gray-300'
                }`}
              >
                {role}
              </Button>
            ))}
          </div>

          {formState.selectedRole === 'Other' && (
            <Input
              placeholder='Please specify role'
              className='mt-2 bg-white focus-visible:ring-0 focus-visible:ring-offset-0'
              value={formState.otherRole}
              onChange={(e) => handleChange('otherRole', e.target.value)}
              disabled={formState.selectedRole !== 'Other'}
            />
          )}
        </div>
      </div>
      <Separator className='md:hidden' />
      <CraitButton onClick={handleSubmit} className='mb-4 ml-auto mr-4 w-[104px] md:w-full'>
        Update
      </CraitButton>
    </>
  );
};
