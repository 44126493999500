const HOST_URL =
  import.meta.env.MODE === 'development' ? 'http://localhost:8080' : 'https://api.crait.it';
const PROD_URL = 'https://api.crait.it';
export const BASE_API_URL = `${HOST_URL}/api/v1`;

export const AuthEndpoint = {
  _PREFIX: '/auth',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  SIGN_UP: '/auth/signup',
  EMAIL_CONFIRM: '/auth/signup-confirmation',
  REFRESH_TOKEN: '/auth/refresh-token',
  FORGOT_PASSWORD: '/auth/password/forgot',
  RESET_PASSWORD: '/auth/password/reset',
  UPDATE_PASSWORD: '/auth/password/update',
  GOOGLE_LOGIN: '/auth/google-login',
  GOOGLE_OAUTH: '/auth/oauth-google',
} as const;

export const INTERCEPTOR_BYPASSED_AUTH_ENDPOINTS = Object.values(AuthEndpoint).filter(
  (endpoint) =>
    endpoint !== AuthEndpoint.RESET_PASSWORD && endpoint !== AuthEndpoint.UPDATE_PASSWORD,
);

export const AccountEndpoint = {
  DELETE_ACCOUNT: '/account/',
  UPDATE_ACCOUNT_INFO: '/account/info',
  GET_ACCOUNT_STATISTICS: '/account/statistics',
  GET_ACCOUNT_MEMBERS: '/account/members',
  ADD_ACCOUNT_MEMBER: '/account/member',
  DELETE_ACCOUNT_MEMBER: '/account/member',
  PATCH_ACCOUNT_MEMBER: '/account/member',
  POST_COMPLETE_ONBOARDING: '/account/complete-onboarding',
  GET_ACCOUNT_PREFERENCES: '/account/preferences',
  DELETE_ACCOUNT_PREFERENCES: '/account/preferences',
  PATCH_ACCOUNT_PREFERENCES: '/account/preferences',
  GET_ACCOUNT_COMPANY_INFO: '/account/company-info',
  PUT_ACCOUNT_COMPANY_INFO: '/account/company-info',
} as const;

export const ProjectEndpoint = {
  BASE: '/workspace/projects',
} as const;

export const ActivityEndpoint = {
  GET_ACTIVITIES: '/workspace/member-activities/',
} as const;

export const ProductEndpoint = {
  BASE: '/workspace/brand-library/products',
  QUICK_UPLOAD: '/workspace/brand-library/products/quick-upload',
  CATEGORIES: '/workspace/brand-library/products/categories',
  PROPERTIES: '/workspace/brand-library/products/properties',
} as const;

export const StyleEndpoint = {
  BASE: '/workspace/brand-library/styles',
  CATEGORIES: '/workspace/brand-library/styles/categories',
} as const;

export const ElementEndpoint = {
  BASE: '/workspace/brand-library/elements',
} as const;

export const TemplateEndpoint = {
  BASE: '/workspace/brand-library/templates',
  CATEGORIES: '/workspace/brand-library/templates/categories',
} as const;

export const BannerEndpoint = {
  BASE: '/workspace/brand-library/banners',
} as const;

export const AiSceneGenerationEndpoint = {
  BASE: '/generations/',
} as const;

export const AiSceneSuggestionsEndpoint = {
  KEYWORD: 'keyword-suggestion',
  PROMPT: 'prompt-completion',
  RECENT: 'recently-used',
  RECOMMENDED: 'recommended-parameters',
  GET_LAYOUT_SIZES: 'layout-sizes',
  POST_LAYOUT_SIZE: 'layout-sizes',
  PUT_LAYOUT_SIZES: 'layout-sizes',
  DELETE_LAYOUT_SIZE: 'layout-sizes',
} as const;

export const AiSceneEditEndpoint = {
  _RAW: '',
  MAGIC_ERASE: 'magic-erase',
  MAGIC_ERASE_V2: 'magic-erase-v2',
  MAGIC_ERASE_PRO: 'magic-erase-pro',
  AI_SKETCH: 'ai-sketching',
  OUTPAINT: 'outpaint',
  USER_EDIT: 'user-edit',
  SAVE: 'save',
  IDENTITY_CONTROL: 'identity-control',
  ENHANCE_QUALITY: 'enhance-quality',
  ENHANCE_DETAILS: 'enhance-details',
  MAGIC_EDIT: 'magic-edit',
  PARTIAL_DETAIL: 'detail-restoration',
  REMOVE_BACKGROUND: 'remove-background',
} as const;

export const ImageProcessEndpoint = {
  REMOVE_BG: 'remove-background',
  ENHANCE: 'enhance-quality',
} as const;

export const RemoveBgSubEndpoint = {
  BULK: '/bulk',
} as const;

export const UploadProcessEndpoint = {
  UPLOAD_IMAGE: 'images',
  UPLOAD_FILE: 'files',
} as const;

export const SearchEndpoint = {
  BASE: '/search',
  ELEMENT_CATEGORIES: '/search/element-categories',
} as const;

export const HistoryEndpoint = {
  BASE: '/workspace/history/ai-scene',
} as const;

export const SavedSettingsEndpoint = {
  BASE: '/workspace/saved-settings',
} as const;

export const BrandKitEndpoint = {
  BASE: '/workspace/brand-library/brandkit',
} as const;

export const BrandKitSubEndpoint = {
  LOGOS: '/logos',
  COLORS: '/colors',
  FONTS: '/fonts',
  BRAND_VOICE_GUIDE: '/brand-voice-guide',
} as const;

export const PaymentPlanEndpoint = {
  PLANS: '/payment/plans',
  PRODUCTS: '/payment/products',
  CUSTOMER: '/payment/customer',
  SUBSCRIPTION: '/payment/subscription',
  PRORATION_AMOUNT: '/payment/subscription/proration-amount',
  APPLY_PROMOTION_CODE: '/payment/subscription/apply-promotion-code',
};

export const MiscEndpoint = {
  CONTACT_US: '/misc/contact-us',
  EMAIL_UNSUBSCRIBE: 'misc/email/unsubscribe',
} as const;
