import MobileWarningIcon from 'assets/icons/mobileWarningIcon.svg?react';

export const MobileWarningContent: React.FC = () => (
  <div className='flex items-center gap-2'>
    <MobileWarningIcon className='h-16 w-16' />
    <div className='space-y-2'>
      <p className='text-sm text-muted-foreground'>
        Please use desktop device for the best experience
      </p>
    </div>
  </div>
);
