import { RoutePaths } from '@/utils/route/constants';
import Logo from 'assets/icons/coming_soon.svg?react';
import MobileWarningIcon from 'assets/icons/mobileWarningIcon.svg?react';

import { Link } from 'react-router-dom';

export const MobileWarningView = () => {
  return (
    <div className='flex size-full items-center justify-center bg-white'>
      <div className='flex h-full max-h-[800px] w-full max-w-[1440px] gap-20 px-20 py-8 sm:px-4  '>
        <div className='relative flex h-full w-[450px] flex-col items-center  justify-center gap-3 md:m-auto lg:m-auto'>
          <div className='absolute inset-0 size-10'>
            <Link to={`/${RoutePaths.LOGIN}`}>
              <Logo className='size-full' cursor='pointer' />
            </Link>
          </div>
          <MobileWarningIcon />

          <h4 className='text-center text-[20px] font-semibold text-c-dark'>
            Mobile devices are not supported at the moment.
          </h4>
          <p className='text-center text-[14px]'>
            Please use desktop device for the best experience. Mobile device support will be
            available soon.
          </p>
        </div>
      </div>
    </div>
  );
};
