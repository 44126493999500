import React, { useState, useRef, useEffect } from 'react';
import Icon from '@/assets/icons/onboardingStepThree.svg?react';
import { ContinueButton } from '../_components/ContinueButton';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { useUpdateCompanyInfoMutation } from 'components/dashboard/profile/helper';
import { COUNTRIES, INDUSTRIES, ROLES } from './constants';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ScrollArea } from 'components/ui/scroll-area';

const scrollToBottom = (container: HTMLElement | null, smooth = false) => {
  if (container?.children.length) {
    const lastElement = container.lastChild as HTMLElement;
    lastElement?.scrollIntoView({
      behavior: smooth ? 'smooth' : 'auto',
      block: 'end',
      inline: 'nearest',
    });
  }
};

type OnboardingWorkInfoStepProps = {
  moveToNextStep: () => void;
};

const INITIAL_STATE = {
  selectedIndustry: '',
  otherIndustry: '',
  selectedRole: '',
  otherRole: '',
  companyName: '',
  countryCode: '90',
  phoneNumber: '',
};

export const WorkInfoStep: React.FC<OnboardingWorkInfoStepProps> = ({ moveToNextStep }) => {
  const [formState, setFormState] = useState(INITIAL_STATE);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isOtherInputVisible, setIsOtherInputVisible] = useState(false);

  const updateCompanyInfoMutation = useUpdateCompanyInfoMutation();

  const sortedCountries = COUNTRIES.sort((a, b) => a.phone_code - b.phone_code);

  const handleChange = (key: string, value: string) => {
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleRoleClick = (role: string) => {
    handleChange('selectedRole', role);
    if (role === 'Other') {
      setIsOtherInputVisible(true);
    } else {
      setIsOtherInputVisible(false);
    }
  };

  // Scroll is added to ensure the "Other role" input is visible when selected, as it initially appears off-screen.
  useEffect(() => {
    if (isOtherInputVisible && scrollContainerRef.current) {
      scrollToBottom(scrollContainerRef.current, true);
    }
  }, [isOtherInputVisible]);

  const handleContinue = () => {
    const {
      companyName,
      countryCode,
      phoneNumber,
      selectedIndustry,
      otherIndustry,
      selectedRole,
      otherRole,
    } = formState;

    const role = selectedRole === 'Other' ? otherRole : selectedRole;
    const industry = selectedIndustry === 'Other' ? otherIndustry : selectedIndustry;

    updateCompanyInfoMutation.mutate(
      {
        companyName,
        phoneNumber,
        phoneCountryCode: parseInt(countryCode),
        industry,
        employeeRole: role,
      },
      {
        onSuccess: () => {
          moveToNextStep();
        },
        onError: (error: any) => {
          console.error('Failed to update company info:', error);
        },
      },
    );
  };

  return (
    <>
      <ScrollArea className='h-[530px] w-[480px] pr-[30px] md:h-[60vh] md:w-auto md:pr-0'>
        <div ref={scrollContainerRef}>
          <div className='flex flex-col items-center'>
            <div className='mb-5'>
              <Icon />
            </div>
            <h4 className='mb-[10px] text-xl font-bold'>Tell us about your work</h4>
            <p className='mb-5 text-center text-[14px] text-[#000000]'>
              Select your industry and role so we can tailor your experience.
            </p>
          </div>

          <div className='mb-2 flex flex-col gap-4 px-1'>
            {/* Company Name Input */}
            <div className='flex flex-col gap-2'>
              <Label htmlFor='company-name'>Company Name</Label>
              <Input
                id='company-name'
                placeholder='Company Name'
                className='bg-white'
                value={formState.companyName}
                onChange={(e) => handleChange('companyName', e.target.value)}
              />
            </div>
            {/* Company Number Input with Country Code */}
            <div className='flex flex-col gap-2'>
              <Label htmlFor='company-number'>Company Number</Label>
              <div className='flex gap-3'>
                <Popover open={open} onOpenChange={setOpen}>
                  <PopoverTrigger asChild className='rounded-md border-[#EAEAEA]'>
                    <Button
                      variant='outline'
                      role='combobox'
                      aria-expanded={open}
                      className='w-[200px] justify-between'
                    >
                      <span className='flex-1 text-main'>
                        {formState.countryCode ? `+${formState.countryCode}` : 'Select code...'}
                      </span>
                      <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className='h-[200px] w-[200px] p-0'>
                    <Command
                      filter={(value, search) => {
                        const normalizedSearch = search.startsWith('+') ? search.slice(1) : search;
                        const extendValue = value + ' ';
                        if (extendValue.startsWith(normalizedSearch)) return 1;
                        return 0;
                      }}
                    >
                      <CommandInput placeholder='Search country code...' />
                      <CommandList>
                        <CommandEmpty>No code found.</CommandEmpty>
                        <CommandGroup>
                          {sortedCountries.map((country) => (
                            <CommandItem
                              key={country.country_en}
                              value={country.phone_code.toString()}
                              onSelect={(currentValue) => {
                                setValue(currentValue === value ? '' : currentValue);
                                handleChange('countryCode', currentValue);
                                setOpen(false);
                              }}
                            >
                              <Check
                                className={cn(
                                  'mr-2 h-4 w-4',
                                  value === country.phone_code.toString()
                                    ? 'opacity-100'
                                    : 'opacity-0',
                                )}
                              />
                              +{country.phone_code.toString()}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>

                <Input
                  placeholder='Enter phone number'
                  type='text'
                  className='bg-white'
                  value={formState.phoneNumber}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^\d\s]/g, '');
                    handleChange('phoneNumber', newValue);
                  }}
                />
              </div>
            </div>

            {/* Industry Selection */}
            <div className='flex flex-col gap-2'>
              <Label>Industry</Label>
              <div className='flex flex-wrap gap-2'>
                {INDUSTRIES.map((industry) => (
                  <Button
                    key={industry}
                    variant='outline'
                    onClick={() => handleChange('selectedIndustry', industry)}
                    className={`h-[26px] rounded border px-2 py-1 text-[12px] font-normal ${
                      formState.selectedIndustry === industry ? 'border-black' : 'border-gray-300'
                    }`}
                  >
                    {industry}
                  </Button>
                ))}
              </div>

              {formState.selectedIndustry === 'Other' && (
                <Input
                  placeholder='Please specify industry'
                  className='mt-2 bg-white'
                  value={formState.otherIndustry}
                  onChange={(e) => handleChange('otherIndustry', e.target.value)}
                />
              )}
            </div>

            {/* Role Selection */}
            <div className='mb-4 flex flex-col gap-2'>
              <Label>Role</Label>
              <div className='flex flex-wrap gap-2'>
                {ROLES.map((role) => (
                  <Button
                    key={role}
                    variant='outline'
                    onClick={() => handleRoleClick(role)}
                    className={`h-[26px] rounded border px-2 py-1 text-[12px] font-normal ${
                      formState.selectedRole === role ? 'border-black' : 'border-gray-300'
                    }`}
                  >
                    {role}
                  </Button>
                ))}
              </div>

              {formState.selectedRole === 'Other' && (
                <Input
                  placeholder='Please specify role'
                  className='mt-2 bg-white'
                  value={formState.otherRole}
                  onChange={(e) => handleChange('otherRole', e.target.value)}
                />
              )}
            </div>
          </div>
        </div>
      </ScrollArea>

      {/* Continue Button */}
      <ContinueButton onClick={handleContinue} />
    </>
  );
};
